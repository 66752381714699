import * as React from 'react';

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader';
import CharismaMessage from '../components/message.tsx';
import { CharismaTrainingApi } from '../api/trainings/CharismaTrainingApi.tsx';
import CharismaGenerateAssessmentForm from './generateAssessmentForm.tsx';
import CharismaTrainingForm  from './trainingForm.tsx';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import {formatDate} from '../utils.tsx'
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import Typography from '@mui/material/Typography';



import { CharismaUserContext } from '../context.tsx';

export default function CharismaTrainingsListItem(props){
        
    const lTraining = props.training;
    const [currentTraining, setCurrentTraining] = React.useState(null);
    const [error, setError] = React.useState(0);
    const [deleteTraining, setDeleteTraining] = React.useState(false);
    const [restoreTraining, setRestoreTraining] = React.useState(false);
    const [generated, setGenerated] = React.useState(false);
    const [name, setName] = React.useState(lTraining.name);
    const [startDate, setStartDate] = React.useState(lTraining.startDate);
    const [endDate, setEndDate] = React.useState(lTraining.endDate);
    const [courses, setCourses] = React.useState([]);
    const [logos, setLogos] = React.useState([]);
    const [createDailyAssessment, setCreateDailyAsseessment] = React.useState(false);
    

    const lCurrentUser = React.useContext(CharismaUserContext);
    
    React.useEffect(()=>{
        setName(lTraining.name);
        setStartDate(lTraining.startDate)
        setEndDate(lTraining.endDate);
        setDeleteTraining(false);
        setRestoreTraining(false);
        setLogos(lTraining.logos);
        const lResponse = CharismaTrainingApi.ListCourses(lTraining.id);
        lResponse.then((value)=>{
            setError(value.error);
            setCurrentTraining(null);
            if(value.error === 0){
                setCourses(value.courses);
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })
    }, [lTraining])
    
    const lTrash : boolean = props.trash === undefined ? false : true; 
    
    function handleCancel(){
        setCurrentTraining(null);
    }
    
    function handleEdit(){
        props.handleEdit(lTraining.id);   
    }
    
    function handleSubmit(pName : string, pStartDate : string, pEndDate: string){
        const lResponse = CharismaTrainingApi.UpdateTraining(pName, pStartDate, pEndDate, lTraining.id);
        lResponse.then((value)=>{
            setError(value.error);
            setCurrentTraining(null);
            if(value.error === 0){
                if(value.training !== undefined && value.training !== null){
                    setName(value.training.name);
                    setStartDate(value.training.startDate);
                    setEndDate(value.training.endDate);
                }
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })
    }
    
    function cancelGenerateDaily(){
        setCreateDailyAsseessment(false);
    }
    
    function submitGenerateDaily(pDate : string, pCourses : number[]){
        const lResponse = CharismaTrainingApi.GenerateAssessment(pDate, lTraining.id, pCourses, 0);
        lResponse.then((value)=>{
            
            if(value.error === 0){
                setCreateDailyAsseessment(false);
                setGenerated(true);
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })
    }
    
    return(
        <Box>
            <Card>
                <CardHeader
                    action={
                        lCurrentUser.profile === 0 ?
                        <div>
                            {lTrash?
                                <IconButton 
                                    onClick={()=>setRestoreTraining(true)}>
                                    <RestoreFromTrashIcon/>
                                </IconButton>
                                :
                                <IconButton 
                                    onClick={()=>handleEdit()}>
                                    <EditIcon/>
                                </IconButton>
                            }
                            <IconButton onClick={()=>setDeleteTraining(true)}>
                                <DeleteIcon/>
                            </IconButton>
                        </div>
                        :
                        null
                    }  
                    title={name}
                    subheader = {'du ' + formatDate(startDate) + ' au ' + formatDate(endDate)}                    
                />
                <CardContent>
                {logos.map((lLogo)=>
                    <img width="150" src={lLogo} />
                )}
                {lCurrentUser.profile === 0 || lCurrentUser.profile === 1?
                    <CardContent>
                        {!lTrash?
                        <div>
                        <Button
                            component={Link}
                            to = {'/training/' + lTraining.id}
                        >
                            Gérer
                        </Button>
                        <Button
                            onClick={()=>setCreateDailyAsseessment(true)}
                        >
                            Créer les bilans Quotidiens
                        </Button>
                        </div>
                        :
                        null
                        }
                    </CardContent>                    
                    :
                    null  
                } 
                {lCurrentUser.profile === 5?
                    <CardContent>
                        <Button
                            component={Link}
                            to = {'/training/' + lTraining.id}
                        >
                            Stagiaires
                        </Button>
                        
                    </CardContent>                    
                    :
                    null  
                }                                                   
                </CardContent>
            </Card>
            {currentTraining !== null ?
                <CharismaTrainingForm
                    name = {currentTraining.name}
                    startDate = {currentTraining.startDate}
                    endDate = {currentTraining.endDate}
                    responsible={currentTraining.responsible}
                    handleSubmit={handleSubmit}
                    handleCancel={handleCancel}
                />
                :
                null    
            }    
            {createDailyAssessment ?
                <CharismaGenerateAssessmentForm
                    courses = {courses}
                    handleCancel={cancelGenerateDaily}
                    handleSubmit={submitGenerateDaily}
                    title={"Création des bilans quotidiens"}
                    minDate={lTraining.startDate}
                    maxDate={lTraining.endDate}
                />
            :
            null
            }
            <Dialog
                open={deleteTraining}
            >
                <DialogTitle>{'Supprimer une formation'}</DialogTitle>
                <DialogContent>
                    {lTrash ?
                        <Typography>{'Etes-vous sûr de vouloir supprimer définitivemenet la formation'} </Typography>
                        :
                        <Typography>{'Etes-vous sûr de vouloir mette à la corbeille la formation'} </Typography>
                    }
                    <Typography>{name} </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setDeleteTraining(false)}>
                        Annuler
                    </Button>
                    <Button onClick={()=>props.handleDeleteTraining(lTraining.id)}>
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={restoreTraining}
            >
                <DialogTitle>{'Réactiver la formation'}</DialogTitle>
                <DialogContent>
                    <Typography>{'Etes-vous sûr de vouloir réactiver la formation'} </Typography>
                    <Typography>{name} </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setRestoreTraining(false)}>
                        Annuler
                    </Button>
                    <Button onClick={()=>props.handleRestoreTraining(lTraining.id)}>
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>
            {
                generated ?                   
                <CharismaMessage
                    message="Bilans générés"
                    handleClose={setGenerated}
                />
                :
                null
            }   
        </Box>      
    );
    
    
}